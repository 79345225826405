@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: Gilroy, sans-serif;
    -webkit-tap-highlight-color: transparent;
    @apply text-base;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #eeeeee;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #6f3c6e;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #532c52;
  }

  .videobar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .videobar::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.15);
  }
  .videobar::-webkit-scrollbar-thumb {
    background-color: #6f3c6e;
  }
  .videobar::-webkit-scrollbar-thumb:hover {
    background-color: #532c52;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("../fonts/gilroy/gilroy-bold.woff2") format("woff2"), url("../fonts/gilroy/gilroy-bold.woff") format("woff"), url("../fonts/gilroy/gilroy-bold.ttf") format("truetype"),
      url("../fonts/gilroy/gilroy-bold.svg#gilroy-bolduploaded_file") format("svg");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("../fonts/gilroy/gilroy-heavy.woff2") format("woff2"), url("../fonts/gilroy/gilroy-heavy.woff") format("woff"),
      url("../fonts/gilroy/gilroy-heavy.ttf") format("truetype"), url("../fonts/gilroy/gilroy-heavy.svg#gilroy-heavyuploaded_file") format("svg");
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("../fonts/gilroy/gilroy-light.woff2") format("woff2"), url("../fonts/gilroy/gilroy-light.woff") format("woff"),
      url("../fonts/gilroy/gilroy-light.ttf") format("truetype"), url("../fonts/gilroy/gilroy-light.svg#gilroy-lightuploaded_file") format("svg");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("../fonts/gilroy/gilroy-medium.woff2") format("woff2"), url("../fonts/gilroy/gilroy-medium.woff") format("woff"),
      url("../fonts/gilroy/gilroy-medium.ttf") format("truetype"), url("../fonts/gilroy/gilroy-medium.svg#gilroy-mediumuploaded_file") format("svg");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("../fonts/gilroy/gilroy-regular.woff2") format("woff2"), url("../fonts/gilroy/gilroy-regular.woff") format("woff"),
      url("../fonts/gilroy/gilroy-regular.ttf") format("truetype"), url("../fonts/gilroy/gilroy-regular.svg#gilroy-regularuploaded_file") format("svg");
    font-weight: normal;
    font-style: normal;
  }

  p {
    /*margin-bottom: 10px;*/
  }

  .site-px {
    @apply px-4 md:px-8;
  }

  ul,
  ol {
    margin-left: 1em;
  }

  ul li::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
    @apply text-tv-yellow dark:text-black;
  }

  .generic-input {
    @apply px-3 py-2 text-sm dark:text-base rounded-lg;
  }
  .generic-input-error {
    @apply px-3 py-2 text-sm dark:text-base border-tv-red border-2;
  }
}
